import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, { selected: _ctx.selected }, {
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        icon: "delete",
        text: "Delete",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeleteFilteredViews(_ctx.selected)))
      })
    ]),
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        icon: "add",
        text: "New view",
        to: { name: 'base.tickets.settings.filteredViews.detailed', params: { new: true } }
      }, null, 8, ["to"])
    ]),
    _: 1
  }, 8, ["selected"]))
}
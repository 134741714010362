import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_tickets_filtered_views_filter = _resolveComponent("tickets-filtered-views-filter")!
  const _component_tickets_filtered_views_table = _resolveComponent("tickets-filtered-views-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-white": _withCtx(() => [
      _createVNode(_component_tickets_filtered_views_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        selected: _ctx.selected,
        "search-placeholder": "Search filtered views"
      }, null, 8, ["search", "selected"]),
      _createVNode(_component_tickets_filtered_views_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.tableItems,
        "item-key": "title",
        "items-name": "views",
        draggable: "",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Filtered views",
        icon: "tmi-filter-list",
        class: "mb-4"
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tickets_filtered_views_actions = _resolveComponent("tickets-filtered-views-actions")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "d-flex align-center font-weight-normal font-color-inherit blue-on-hover",
        to: { name: 'base.tickets.settings.filteredViews.detailed' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_emoji, {
            name: props.row.emoji,
            class: "mr-2"
          }, null, 8, ["name"]),
          _createTextVNode(" " + _toDisplayString(props.row.title), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-visible-slot": _withCtx((props) => [
      _createVNode(_component_tm_badge, { static: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.visible), 1)
        ]),
        _: 2
      }, 1024)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_tickets_filtered_views_actions)
    ]),
    _: 1
  }))
}

import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import { useModals } from '@/compositions/modals'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const { openModal } = useModals()
    const { openDeleteFilteredView } = useTicketsOpenModals()

    const handleDuplicate = () => {
      openModal('duplicate', {
        itemName: 'filtered view',
        originalName: 'Open SENDER ID notifications',
        showSharing: true,
      })
    }

    return {
      openDeleteFilteredView,
      openModal,
      handleDuplicate,
    }
  },
})

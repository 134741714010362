
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TicketsFilteredViewsActions from '@/components/pages/tickets/settings/filteredViews/TicketsFilteredViewsActions.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  components: {
    TmEmoji,
    TmBadge,
    TicketsFilteredViewsActions,
    TmTable,
  },
})

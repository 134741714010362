
import { defineComponent, ref, computed } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import TicketsFilteredViewsFilter from '@/components/pages/tickets/settings/filteredViews/TicketsFilteredViewsFilter.vue'
import TicketsFilteredViewsTable from '@/components/pages/tickets/settings/filteredViews/TicketsFilteredViewsTable.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { ticketsSidebarRoutes, ticketsSystemSidebarRoutes, ticketsStartingSidebarRoutes } from '@/router/sidebarRoutes/ticketsRoutes'
import { randomDate, randomIntFromInterval } from '@/services/utils'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    PageContent,
    TicketsFilteredViewsTable,
    TicketsFilteredViewsFilter,
    DetailsHero,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Filtered views', url: '' },
    ]
    const search = ref()
    const selected = ref()
    const tableItems = computed(() => {
      let items

      if (isEmptyMode.value) {
        items = ticketsStartingSidebarRoutes.value.filter((e: any) => e.itemType !== 'title')
      } else {
        items = ticketsSidebarRoutes.concat(ticketsSystemSidebarRoutes).filter((e: any) => e.itemType !== 'title')
      }

      const visibleArr = [
        'All users',
        'Only me',
        'Support',
        'Marketing',
      ]

      return items.map((e: any) => ({
        ...e,
        visible: visibleArr[randomIntFromInterval(0, visibleArr.length - 1)],
        lastUpdated: randomDate(new Date(2024, 0, 1), new Date()),
      }))
    })

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name-slot' },
      { text: 'Tickets', value: 'number' },
      { text: 'Visible to', value: 'visible-slot' },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val) },
    ])

    return {
      tableItems,
      tableHeaders,
      search,
      selected,
      breadcrumbs,
    }
  },
})
